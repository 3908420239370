import {
  PlanName,
  useCreateOrUpdateWeldSubscriptionMutation,
  useStripeSubscriptionLazyQuery,
} from "@/apollo/types";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useCurrentAccount } from "@/providers/account";
import { useSocketEvent } from "@/socket/SocketContext";
import * as Sentry from "@sentry/react";

import { CheckoutContext } from "../ConfirmSubscriptionContainer";
import { useRevalidateSubscriptionCache } from "./useRevalidateSubscriptionCache";

export function useCreateOrUpdateSubscription(props: {
  onSubscriptionCreated?: (params: { planName?: PlanName }) => void;
  onSubscriptionPlanChanged?: (params: { planName?: PlanName }) => void;
  onError?: (error: Error) => void;
}) {
  const account = useCurrentAccount();
  const mixpanel = useMixpanel();

  const [createSubscription, { called, error }] =
    useCreateOrUpdateWeldSubscriptionMutation({
      onError: props.onError,
    });

  const [fetchStripeSubscription] = useStripeSubscriptionLazyQuery({
    fetchPolicy: "network-only",
    variables: { slug: account.slug },
  });

  const revalidateCache = useRevalidateSubscriptionCache();
  useSocketEvent("stripe:subscription-created", {
    async onMessage() {
      mixpanel.track("Checkout Subscription Created");
      await revalidateCache();

      const { data } = await fetchStripeSubscription();
      const planName =
        data?.accountBySlug.stripeSubscription?.stripeSubscriptionDetails
          .planName;
      props.onSubscriptionCreated?.({ planName });
    },
  });

  useSocketEvent("stripe:subscription-plan-changed", {
    async onMessage() {
      mixpanel.track("Checkout Subscription Updated");
      await revalidateCache();
      const { data } = await fetchStripeSubscription();
      const planName =
        data?.accountBySlug.stripeSubscription?.stripeSubscriptionDetails
          .planName;
      props.onSubscriptionPlanChanged?.({ planName });
    },
  });

  const createSubscriptionHandler = async (
    params: CheckoutContext & { paymentMethodId?: string },
  ) => {
    try {
      const result = await createSubscription({
        variables: {
          customerId: params.customerId,
          priceId: params.priceId,
          currency: params.currency,
          numConnectors: params.numConnectors,
          numUsers: params.numUsers,
          paymentMethodId: params.paymentMethodId,
        },
      });

      if (result.data) {
        return {
          data: result.data.createOrUpdateWeldSubscription.id,
          error: undefined,
        };
      }
      let errorMessage = "Failed to create subscription";
      if (result.errors instanceof Error) {
        errorMessage = result.errors.message;
      } else if (Array.isArray(result.errors)) {
        errorMessage = result.errors.map((e) => e.message).join(", ");
      }
      Sentry.captureException(error, {
        extra: {
          message: "Create subscription failed",
          errors: errorMessage,
        },
      });
      return {
        data: undefined,
        error: {
          code: "create_subscription_failed",
          message: errorMessage,
        },
      };
    } catch (e) {
      return {
        data: undefined,
        error: {
          code: "create_subscription_failed",
          message:
            e instanceof Error ? e.message : "Failed to create subscription",
        },
      };
    }
  };

  return [createSubscriptionHandler, { loading: called && !error }] as const;
}
